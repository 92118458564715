import React, { useRef } from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Image from "../components/image"
import SEO from "../components/seo"

import lpHeroDesktop2x from "../images/lp1/lp1-hero@2x.jpg"
import lpHeroMobile2x from "../images/lp1/lp1-hero-mobile@2x.jpg"
import ChocolateChip from "../images/chocolate-chip.png"
import ChocolateChipSpoon from "../images/lp1/spoon-chocolatechip.png"
import PBSpoon from "../images/lp1/pb-spoon.png"
import PeanutButter from "../images/peanut-butter.png"
import FudgeBrownie from "../images/fudge-brownie.png"
import FudgeBrownieSpoon from "../images/lp1/spoon-fudgebrownie.png"
import AccoladesDesktop from "../images/lp1/accolades-desktop.jpg"
import AccoladesMobile from "../images/lp1/accolades-mobile.jpg"
import ReadyDesktop from "../images/lp1/ready-desktop.png"
import ReadyMobile from "../images/lp1/ready-mobile.png"
import Stars from "../images/stars.png"
import TryImg from "../images/try-img-1.png"
import TryCtaDesktop from "../images/try-cta.png"
import TryCtaMobile from "../images/try-cta-mobile.png"
import logo from "../images/logo.png"

import * as Icon from "../components/Icons"

const IndexPage = () => {
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  return (
    <Layout>
      <SEO title="Starter Packs" />
      <div className="w-full relative">
        <div
          className="absolute"
          style={{ width: 150, height: 150, top: "10px", left: "10px" }}
        >
          <a href="https://pssnacks.com">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="absolute hidden md:block desktop-hero-text">
          <h1 className="font-campton text-brand-crimson-500 tracking-wider leading-relaxed text-4xl">
            We're taking a radically unique{" "}
            <span class="highlighted-text">ingredient </span>
            approach to modernize nostalgic favorites.
          </h1>
          <a
            className="px-8 py-2 font-codesaver bg-brand-astronaut-500 text-white rounded-md uppercase hover:bg-brand-astronaut-700 transition-all duration-150"
            href="https://ps-snacks.com/collections/products/products/cookie-dough-sample-pack"
          >
            Shop Now
          </a>
        </div>
        <div className="hidden md:block">
          <img
            src={lpHeroDesktop2x}
            alt=""
            style={{ maxHeight: "716px" }}
            class="w-full object-cover"
          />
        </div>
        <div className="md:hidden">
          <img
            src={lpHeroMobile2x}
            alt=""
            style={{ maxHeight: "600px" }}
            className="w-full object-cover"
          />
        </div>
      </div>

      <div className="w-full relative" style={{ backgroundColor: "#c7e6e3" }}>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-5xl mx-auto tracking-wider text-center">
            <div className="md:hidden mb-12 pt-8 pb-12 -mt-6">
              <h1 className="font-campton text-brand-crimson-500 tracking-wider leading-relaxed text-3xl">
                We're taking a radically unique{" "}
                <span class="highlighted-text">ingredient </span>
                approach to modernize nostalgic favorites.
              </h1>
              <a
                className="px-8 py-2 font-codesaver bg-brand-astronaut-500 text-white rounded-md uppercase hover:bg-brand-astronaut-700 transition-all duration-150"
                href="https://ps-snacks.com/collections/products/products/cookie-dough-sample-pack"
              >
                Shop Now
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-5xl mx-auto tracking-wider text-center">
            <h2 className="uppercase text-brand-crimson-500 text-center font-campton text-2xl md:text-3xl">
              Our <span className="highlighted-text">sweet</span> starter packs
              include:
            </h2>
            <h3 className="text-brand-astronaut-500 font-campton text-lg sm:text-xl">
              Get free shipping with 2 or more packs!
            </h3>

            <div className="mt-16 md:mt-20 relative">
              <div className="hidden md:grid md:grid-cols-3 md:gap-20">
                {/* Desktop */}
                <div className="grid grid-cols-1 text-center mt-12">
                  <h4 className="font-campton">
                    Peanut Butter <br />
                    Cookie Dough
                  </h4>
                  <div
                    style={{
                      transform: "rotate(-11deg)",
                    }}
                  >
                    <img src={PeanutButter} alt="" className="z-10" />
                  </div>
                </div>
                <div className="grid grid-cols-1 text-center mb-12">
                  <h4 className="font-campton">
                    Chocolate Chip <br />
                    Cookie Dough
                  </h4>
                  <div>
                    <img src={ChocolateChip} alt="" className="z-10" />
                  </div>
                </div>
                <div className="grid grid-cols-1 text-center mt-12">
                  <h4 className="font-campton">
                    Fudge Brownie <br />
                    Cookie Dough
                  </h4>
                  <div style={{ transform: "rotate(11deg)" }}>
                    <img src={FudgeBrownie} alt="" className="z-10" />
                  </div>
                </div>
              </div>
              {/* Mobile */}
              <div className="flex md:hidden flex-col items-start mb-4">
                <div
                  className="grid grid-col-1 gap-4"
                  style={{ alignSelf: "start" }}
                >
                  <div>
                    <img
                      src={PeanutButter}
                      alt=""
                      className="z-10"
                      style={{ maxWidth: 250 }}
                    />
                  </div>
                  <h4 className="font-campton text-center">
                    Peanut Butter <br />
                    Cookie Dough
                  </h4>
                </div>
              </div>
              <div className="flex md:hidden flex-col items-end mb-4">
                <div className="grid grid-col-1 gap-4">
                  <div>
                    <img
                      src={ChocolateChip}
                      alt=""
                      className="z-10"
                      style={{ maxWidth: 250 }}
                    />
                  </div>
                  <h4 className="font-campton text-center">
                    Chocolate Chip <br />
                    Cookie Dough
                  </h4>
                </div>
              </div>
              <div className="flex md:hidden flex-col items-start mb-4">
                <div className="grid grid-col-1 gap-4">
                  <div>
                    <img
                      src={FudgeBrownie}
                      alt=""
                      className="z-10"
                      style={{ maxWidth: 250 }}
                    />
                  </div>
                  <h4 className="font-campton text-center">
                    Fudge Brownie <br />
                    Cookie Dough
                  </h4>
                </div>
              </div>
              <div className="flex items-center justify-center pb-6 mb:pb-0">
                <a href="https://ps-snacks.com/collections/products/products/cookie-dough-sample-pack">
                  <div className="px-8 py-2 font-codesaver bg-brand-astronaut-500 text-white rounded-md uppercase hover:bg-brand-astronaut-700 transition-all duration-150">
                    Try Now
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="text-brand-wax-flower-500 absolute block"
          style={{ zIndex: -50, bottom: 0 }}
        >
          <path
            fill="currentColor"
            fill-opacity="1"
            d="M0,160L40,133.3C80,107,160,53,240,58.7C320,64,400,128,480,165.3C560,203,640,213,720,186.7C800,160,880,96,960,64C1040,32,1120,32,1200,58.7C1280,85,1360,139,1400,165.3L1440,192L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
        <div className="w-full h-12 hidden md:block bg-brand-wax-flower-500"></div>
      </div>
      <div className="bg-brand-humming-bird-500 py-16">
        {/* Desktop */}
        <div className="hidden md:grid grid-cols-2 gap-12 my-12">
          <div className="px-12 tracking-widest leading-normal max-w-2xl mx-auto">
            <h3 className="font-campton text-4xl lg:text-5xl text-brand-spectra-500">
              Satisfy
            </h3>
            <p className="font-codesaver text-xl lg:text-2xl">
              your sweet tooth with our original chocolate chip cookie dough, a
              delicious take on the classic chocolate chip cookie recipe blended
              with creamy almond butter, chocolate chips and hints of vanilla.
            </p>
          </div>
          <div style={{ justifySelf: "end", alignSelf: "center" }}>
            <img src={ChocolateChipSpoon} alt="" style={{ maxHeight: 200 }} />
          </div>
        </div>

        <div className="hidden md:grid grid-cols-2 gap-12 my-12">
          <div style={{ justifySelf: "start", alignSelf: "center" }}>
            <img src={FudgeBrownieSpoon} alt="" style={{ maxHeight: 200 }} />
          </div>
          <div className="px-12 tracking-widest leading-normal max-w-2xl mx-auto">
            <h3 className="font-campton text-4xl lg:text-5xl text-brand-spectra-500">
              Dig
            </h3>
            <p className="font-codesaver text-xl lg:text-2xl">
              into double fudge goodness and enjoy this brownie batter, a rich
              blend of cocoa powder, almond butter and chocolate chips.
            </p>
          </div>
        </div>

        <div className="hidden md:grid grid-cols-2 gap-12 my-12">
          <div className="px-12 tracking-widest leading-normal max-w-2xl mx-auto">
            <h3 className="font-campton text-4xl lg:text-5xl text-brand-spectra-500">
              Enjoy
            </h3>
            <p className="font-codesaver text-xl lg:text-2xl">
              this perfect combo of smooth peanut butter and chocolate chips and
              give those PB cups a run for their money!
            </p>
          </div>
          <div style={{ justifySelf: "end", alignSelf: "center" }}>
            <img src={PBSpoon} alt="" style={{ maxHeight: 200 }} />
          </div>
        </div>

        {/* Mobile */}
        <div className="grid md:hidden grid-cols-1 gap-12 my-12">
          <div className="px-12 tracking-widest leading-normal max-w-2xl mx-auto">
            <h3 className="font-campton text-4xl lg:text-5xl text-brand-spectra-500">
              Satisfy
            </h3>
            <p className="font-codesaver text-xl lg:text-2xl">
              your sweet tooth with our original chocolate chip cookie dough, a
              delicious take on the classic chocolate chip cookie recipe blended
              with creamy almond butter, chocolate chips and hints of vanilla.
            </p>
          </div>
          <div style={{ justifySelf: "end", alignSelf: "center" }}>
            <img src={ChocolateChipSpoon} alt="" style={{ maxHeight: 150 }} />
          </div>
        </div>

        <div className="grid md:hidden grid-cols-1 gap-12 my-12">
          <div className="px-12 tracking-widest leading-normal max-w-2xl mx-auto">
            <h3 className="font-campton text-4xl lg:text-5xl text-brand-spectra-500">
              Dig
            </h3>
            <p className="font-codesaver text-xl lg:text-2xl">
              into double fudge goodness and enjoy this brownie batter, a rich
              blend of cocoa powder, almond butter and chocolate chips.
            </p>
          </div>

          <div style={{ justifySelf: "start", alignSelf: "center" }}>
            <img src={FudgeBrownieSpoon} alt="" style={{ maxHeight: 150 }} />
          </div>
        </div>

        <div className="grid md:hidden grid-cols-1 gap-12 my-12">
          <div className="px-12 tracking-widest leading-normal max-w-2xl mx-auto">
            <h3 className="font-campton text-4xl lg:text-5xl text-brand-spectra-500">
              Enjoy
            </h3>
            <p className="font-codesaver text-xl lg:text-2xl">
              this perfect combo of smooth peanut butter and chocolate chips and
              give those PB cups a run for their money!
            </p>
          </div>
          <div style={{ justifySelf: "end", alignSelf: "center" }}>
            <img src={PBSpoon} alt="" style={{ maxHeight: 150 }} />
          </div>
        </div>
      </div>

      <div
        className="w-full bg-white px-8 flex flex-col items-center justify-center py-12"
        ref={myRef}
      >
        <h2 className="font-nickainley font-hairline text-brand-crimson-500 text-4xl md:text-5xl text-center">
          P.S. It's all about our first ingredient
        </h2>
        <img src={AccoladesDesktop} alt="" className="hidden sm:block" />
        <img src={AccoladesMobile} alt="" className="sm:hidden" />
      </div>

      <div className="w-full bg-brand-crimson-500 py-12 px-8 flex flex-col items-center justify-center">
        <h2 className="font-campton text-white text-3xl md:text-4xl text-center">
          READY TO EAT, ANYTIME OF DAY
        </h2>
        <img
          src={ReadyDesktop}
          alt=""
          className="mt-8 hidden sm:block"
          style={{ maxHeight: 694 }}
        />
        <img src={ReadyMobile} alt="" className="mt-8 sm:hidden" />
      </div>

      <div className="w-full px-8 flex-col items-center justify-center py-12">
        <h2 className="uppercase font-campton text-3xl md:text-4xl text-brand-crimson-500 text-center">
          Don't take our <span className="highlighted-text">word</span> for it
        </h2>
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <article className="rounded bg-brand-humming-bird-500 pt-12 px-12 pb-4 shadow-lg">
              <h4 className="font-campton text-3xl text-center">Splendid!</h4>
              <img src={Stars} alt="" className="mx-auto" />
              <p className="font-codesaver tracking-wider leading-relaxed text-xl">
                As a gluten-intolerant college student this is so perfect for
                those night where I can finally binge Netflix all while eating
                cookie dough!
              </p>
              <p className="font-codesaver tracking-wider leading-relaxed text-xl">
                TOTES AMAZING
              </p>
              <p className="text-right font-campton text-brand-spectra-500 text-xl tracking-wider leading-relaxed">
                Jenna Cowerly
              </p>
            </article>
            <article className="rounded bg-brand-humming-bird-500 pt-12 px-12 pb-4 shadow-lg">
              <h4 className="font-campton text-3xl text-center">Delicious!</h4>
              <img src={Stars} alt="" className="mx-auto" />
              <p className="font-codesaver tracking-wider leading-relaxed text-xl">
                My wife and kid are both vegan and fortunately I’m not! But it
                does mean I want to make them happy, so for a dough that does
                not have eggs this is perfect for my child to go wild!
              </p>
              <p className="text-right font-campton text-brand-spectra-500 text-xl tracking-wider leading-relaxed">
                Tracy Adams
              </p>
            </article>
          </div>
        </div>
      </div>

      <div className="w-full -mt-14">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="text-brand-wax-flower-500"
        >
          <path
            fill="currentColor"
            fill-opacity="1"
            d="M0,256L48,234.7C96,213,192,171,288,170.7C384,171,480,213,576,234.7C672,256,768,256,864,250.7C960,245,1056,235,1152,245.3C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="w-full pb-12 bg-brand-wax-flower-500 px-8">
        <h2 className="text-3xl md:text-4xl font-campton tracking-wider text-brand-astronaut-500 uppercase text-center">
          Try our products <span className="highlighted-text">today</span>!
        </h2>
        <p className="text-xl md:text-2xl font-campton tracking-wider text-brand-astronaut-500 text-center my-16">
          Get free shipping with 2 sampler packs!
        </p>
        <div className="max-w-5xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-24">
            <div className="hidden sm:block">
              <img src={TryImg} alt="" />
            </div>
            <div className="hidden sm:block">
              <a href="https://ps-snacks.com/products/cookie-dough-sample-pack">
                <img src={TryCtaDesktop} alt="" />
              </a>
            </div>
            <div className="sm:hidden">
              <a href="https://ps-snacks.com/products/cookie-dough-sample-pack">
                <img src={TryCtaMobile} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:p-4">
        <div className="w-full md:max-w-2xl bg-brand-crimson-500 md:mx-auto md:-mt-12 p-8 md:rounded text-center">
          <p className="text-white font-codesaver text-xl md:text-2xl tracking-wide m-0 leading-relaxed">
            You can store it frozen for up to a year! Otherwise, keep fresh in
            the refrigerator for up to 6 weeks (42 days) from the time it
            defrosts.
          </p>
        </div>
      </div>
    </Layout>
    // <div class="relative text-center bg-white">
    //   <h1>Hello, world!</h1>
    //   <p>Check out my awesome waves!</p>
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     viewBox="0 0 1440 320"
    //     class="block text-brand-wax-flower-500"
    //   >
    //     <path
    //       fill="currentColor"
    //       fill-opacity="1"
    //       d="M0,128L48,112C96,96,192,64,288,96C384,128,480,224,576,224C672,224,768,128,864,96C960,64,1056,96,1152,138.7C1248,181,1344,235,1392,261.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
    //     ></path>
    //   </svg>
    // </div>
  )
}

export default IndexPage
